import axios from "axios"
import auth from "../auth"

// API LOCATION
const baseUrl = `${process.env.REACT_APP_API}/api/`

const api = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})
// * docker-less dev API location
// const  baseUrl = "http://0.0.0.0:8000/" //  transactions/list"; // for some reason http://0.0.0.0:8000 stopped working, now use localhost

// if(process.env.NODE_ENV === 'development') {
//     baseUrl = "http://localhost:8000/"
// }

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error.response ? error.response.status : null
    // go to login if error is a 401
    if (statusCode === 401) {
      localStorage.removeItem("userState")
      localStorage.removeItem("budState")
      auth.logout("/login")
    }
    return Promise.reject(error)
  },
)

export default api
