import dropdown from "../../assets/dropdown.webp"
import {useState} from "react";

const HomepageFAQs = () => {
    const [openQuestions, setOpenQuestions] = useState([])

    const questions = [
        {
            "question": "What does Snapshot do?",
            "answer": "Snapshot uses open banking to create two indispensable reports. One is to assist in settling the financial and legal affairs should you die, the other is to provide bespoke insight to help you gain a comprehensive understanding of your finances"
        },
        {
            "question": "How does Snapshot work?",
            "answer": "Snapshot analyses and orders ‘Open Banking’ data for an up-to-the-minute financial overview to create the ‘Personal’ report. This report is added to a list of your added data to produce the ‘Executor’ report."
        },
        {
            "question": "Is it secure?",
            "answer": "We use the same security data protection protocols as the banks. We also use the same end to end 256-bit AES encryption. You are in safe hands."
        },
        {
            "question": "Is it complicated?",
            "answer": "The initial 3 minutes will give access to most of your important financial data. Uploading of further legal documents and wishes can be as comprehensive as you wish it to be."
        },
        {
            "question": "What happens after I set it up?",
            "answer": "Once set up, the data remains in place. Whilst the subscription is being paid it is accessible by the yourself and any nominated persons.  "
        },
        {
            "question": "How does the banking data stay up-to-date?",
            "answer": "You will be notified on a regular basis to ‘Refresh’ your open banking connections to keep data up-to-date. The process involves a 30 second login at least every 3 months."
        },
        {
            "question": "What is in the ‘Executors Report’?",
            "answer": "In addition to the financial data, the ‘Executors Report’ will contain all the legal and wishes information that you have uploaded."
        },
        {
            "question": "What is in the ‘Personal Report’?",
            "answer": "The ‘Personal Report’ will contain customized insights to help you gain a comprehensive understanding of your financial situation. Your data is collected and processed to offer personalized recommendations, analysis, or advice to improve your financial management skills."
        },
        {
            "question": "Why should you appoint nominees?",
            "answer": "The ‘Executors Report’ is specifically created to help settle your estate. Allowing access to this via a nominee appointment is a way to do this."
        },
        {
            "question": "Who sees the information?",
            "answer": "You and your nominated persons. No one else."
        },
        {
            "question": "Who should you nominate?",
            "answer": "The Person or persons should be considered by you to be Honest, trustworthy, competent and willing. It is recommended that you have a conversation with the potential executor/s to gauge their willingness to take on the task. In some cases it may be appropriate to take on a professional executor, especially if the estate is complicated."
        },
        {
            "question": "What happens when I die?",
            "answer": "During setup, you will have appointed a nominee who will have instructions of what to do. It is their responsibility to furnish Snapshot with a death certificate at which point they will have access to the ‘Executors Report’ and the documents stored in the Snapshot vault."
        },
        {
            "question": "Are there more costs when I die?",
            "answer": "No. We release the information to your nominees as part of the service. We believe we will actually save your family a lot of money, time and stress with the information we can share with them from what you put together."
        },
        {
            "question": "Why bother using Snapshot?",
            "answer": "Snapshot is a tool to simplify settling a will. Bereavement when it happens is debilitating. A grieving friend or relative may be greatly assisted by having pertinent information to hand. Distress is minimised when things are not left to chance and your wishes are known."
        },
        {
            "question": "What if I forget about it?",
            "answer": "Well ideally you look at this now and then but our philosophy is very much that you should go and get on with your life and enjoy it for a long time. Just in case though and because accidents happen, it's good to have this sorted in the background."
        },
        {
            "question": "What happens if I leave my job?",
            "answer": "The account will remain open. A 90 day period will give you time to set up a direct debit to continue the subscription. There is an affiliate reward scheme for you if you successfully introduce Snapshot to their new employer if it is not already in place."
        },
        {
            "question": "Can you employer see your information?",
            "answer": "No. We can't see it either. Only you see your data and access to your Headstart Report and anything in the vault is only permitted after you have died."
        },
        {
            "question": "Does Snapshot share subscriber data with anyone?",
            "answer": "Snapshot does not share your information with anyone. Anonymised data is reviewed by way of an algorithm to ensure services offered by Snapshot are relevant."
        },
        {
            "question": "What happens if there is a data breach?",
            "answer": "We are fully regulated and adhere to the standards set by the Government. If there is any data breach then we are liable for that and will inform you about it immediately but we have exceptionally high standards in order to be able to work with banks and credit agencies safely so hopefully you find that reassuring."
        },
        {
            "question": "What changes can I make to my account?",
            "answer": "The Snapshot report is the basis of the account. This can be expanded to include all manner of legal documents, instructions, letters of wishes and personal items you would like to include that are not captured automatically by our technology."
        },
        {
            "question": "Can I change who I nominate?",
            "answer": "Yes, your nominees can be changed, together with their access as many times as is required."
        },
        {
            "question": "Does this affect my Will?",
            "answer": "No, Snapshot is designed to give access to chosen nominees to facilitate executing a will. There are no legal implications. It does however help to prevent fraud and to halt payments out of a bank account after your death."
        }
    ]

    return (
        <div className="home-page-faq-container">
            <a className="anchor" id="faqs" />
            <div className="home-page-faq-title"> FAQs </div>
            <div className="home-page-faq-description"> Answers to our frequently asked questions </div>
            <div className="home-page-faq-question-container">
                {questions && questions.map((question, idx) => {
                    return (
                        <div key={idx} tabIndex="0" className={idx === 0 ? "home-page-faq-question first" : "home-page-faq-question"} onClick={(ev) => {
                                 let oqs = [...openQuestions]
                                 oqs[idx] = !oqs[idx]
                                 setOpenQuestions(oqs)}}>
                            {question.question}
                            <img src={dropdown} alt="dropdown arrow" style={openQuestions[idx] ? {transform: "rotate(180deg)"} : {transform: "rotate(0deg)"}}/>
                            {openQuestions[idx] ?
                                <div className="home-page-faq-answer">
                                    {question.answer}
                                </div>
                                :
                                null
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default HomepageFAQs