const formatDateLong = (string_date) => {
  const date = new Date(string_date)
  return date.toLocaleDateString("en-uk", { year: "numeric", month: "long", day: "numeric" })
}

const formatDateShort = (string_date) => {
  const date = new Date(string_date)
  return date.toLocaleDateString("en-uk", { year: "numeric", month: "numeric", day: "numeric" })
}

const formatDate = (string_date) => {
  const date = new Date(string_date)
  return date.toLocaleDateString("en-uk")
}

const formatDateReportHeader = (string_date) => {
  const date = new Date(string_date)
  return date.toLocaleDateString("en-uk", { year: "numeric", month: "long" })
}
function getTransactionLastDateShort(transaction) {
  if (transaction.date) {
    return formatDateShort(transaction.date)
  }
  if ("date_time" in transaction && transaction.date_time) {
    return formatDateShort(transaction.date_time)
  }
  return "** ******* ****"
}

export default {
  formatDate,
  formatDateLong,
  formatDateShort,
  getTransactionLastDateShort,
  formatDateReportHeader,
}
