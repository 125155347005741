import SideNav from "../../components/SideNav"
import { useEffect, useState } from "react"
import users_api from "../../api/users"
import outgoings_api from "../../api/outgoings"
import TransactionsTable from "../../components/TransactionsTable"
import GenericDialog from "../../components/GenericDialog"
import { useNavigate } from "react-router-dom"
import stripe_api from "../../api/stripe"
import PlusUpgradeBanner from "../../components/PlusUpgradeBanner"

const Profile = (_props) => {
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [address, setAddress] = useState("")
  const [hasMfa, setHasMfa] = useState(false)

  const [dateOfBirthDay, setDateOfBirthDay] = useState("")
  const [dateOfBirthMonth, setDateOfBirthMonth] = useState("")
  const [dateOfBirthYear, setDateOfBirthYear] = useState("")

  const [hiddenTransactions, setHiddenTransactions] = useState()
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const okButton = [{ text: "OK", id: "1" }]
  const [error, setError] = useState([])
  const [user, setUser] = useState()

  const [plus, setPlus] = useState(false)
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUser()
        const userId = user.id
        GetUser()
        getTransactions(userId)
      } catch (e) {
        console.error("failed to fetch data", e)
      }
    }
    fetchData()
  }, [])

  const getUser = async () => {
    try {
      const response = await users_api.getProfile()
      setUser(response.data.profile.user)
      return response.data.profile.user
    } catch (e) {
      console.error(e)
    }
  }

  const getTransactions = (userId) => {
    const id = userId ?? user?.id
    if (!id) throw new Error("user id is required")
    const response = outgoings_api.getHiddenTransactions({ userId: id })
    response
      .then((response) => {
        const onlyHidden = response.data.data.filter((tx) => tx.is_hidden === true)
        setHiddenTransactions(onlyHidden)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleOKDialogResult = () => {
    setShowInfoDialog(false)
    setShowErrorDialog(false)
  }

  const GetUser = () => {
    const response = users_api.getProfile()
    response
      .then((response) => {
        const user = response?.data?.profile?.user
        setFirstName(user.first_name)
        setLastName(user.last_name)
        setEmail(user.email)
        setMobileNumber(user.phone)
        setAddress(user.address ? user.address : "")
        if (user.dob) {
          const [year, month, day] = user.dob.split(" ")[0].split("-")
          setDateOfBirthYear(year)
          setDateOfBirthMonth(month)
          setDateOfBirthDay(day)
        }
        setHasMfa(user.mfa_enabled)
        setPlus(response.data.profile.plus)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSecuritySubmit = (event) => {
    event.preventDefault()
    if (hasMfa) {
      navigate("/disablemfa")
    } else {
      navigate("/enablemfa")
    }
    return true
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    UpdateUser()
    return true
  }

  const UpdateUser = () => {
    const dob = new Date(`${dateOfBirthMonth}/${dateOfBirthDay}/${dateOfBirthYear}`).toDateString()
    const formData = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone: mobileNumber,
      address: address,
      dob: dob,
    }
    const response = users_api.updateUser(formData)
    response
      .then((_response) => {
        setShowInfoDialog(true)
        GetUser()
      })
      .catch((err) => {
        const message = []
        message.push("Unable to save profile information.")
        setError(message)
        setShowErrorDialog(true)
        console.log(err)
      })
  }

  const CancelUpdate = () => {
    GetUser()
  }

  const CreateCheckOutSession = (type) => {
    const response = stripe_api.createCheckoutSession(type, "profile?subscribe=complete", "profile")
    response
      .then((response) => {
        const url = response.data.url
        window.location.replace(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const CreatePortalSession = () => {
    const response = stripe_api.createPortalSession("profile")
    response
      .then((response) => {
        const url = response.data.url
        window.location.replace(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const ToggleUpgradeBanner = () => {
    setShowUpgradeDialog(!showUpgradeDialog)
  }

  return (
    <div className="page-container">
      <SideNav />
      <div className="profile-subpage-container">
        <div className="profile-page-title">Profile</div>
        <div className="profile-form-container">
          <form name="profile-form" onSubmit={handleSubmit}>
            <div className="account-details-title">Account details</div>
            <div className="account-form-container">
              <div className="profile-form-row-container col">
                <div className="col">
                  <div className="profile-form-element-container-multiple">
                    <label htmlFor="firstName-input">First name</label>
                    <input
                      type="text"
                      id="firstName-input"
                      value={firstName}
                      onChange={(ev) => {
                        setFirstName(ev.target.value)
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="profile-form-element-container-multiple">
                    <label htmlFor="lastName-input">Last name</label>
                    <input
                      id="lastName-input"
                      type="text"
                      value={lastName}
                      onChange={(ev) => {
                        setLastName(ev.target.value)
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="profile-form-element-container-multiple">
                    <label htmlFor="phone-input">Phone number</label>
                    <input
                      id="phone-input"
                      disabled
                      type="text"
                      value={mobileNumber}
                      onChange={(ev) => {
                        setMobileNumber(ev.target.value)
                      }}
                      pattern="(\+)?[0-9]+"
                      title="Must be a valid phone number, no spaces, international dialling code is optional"
                    />
                  </div>
                </div>
              </div>
              <div className="profile-form-row-container col">
                <div className="col">
                  <div className="profile-form-element-container-multiple">
                    <label htmlFor="email-input">Email address</label>
                    <input
                      id="email-input"
                      type="email"
                      value={email}
                      onChange={(ev) => {
                        setEmail(ev.target.value)
                      }}
                      required
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="profile-form-element-container-multiple">
                    <label htmlFor="dob-field-1">Date of birth</label>
                    <div className="profile-dob-input-container">
                      <input
                        type="text"
                        value={dateOfBirthDay}
                        className="profile-dob-input day"
                        id="dob-field-1"
                        onChange={(ev) => {
                          setDateOfBirthDay(ev.target.value)
                          if (ev.target.value.length === 2) document.getElementById("dob-field-2").focus()
                        }}
                        pattern={"^[0-9]{2}$"}
                        maxLength={2}
                        title="Please enter a valid day e.g. 12"
                      />
                      /
                      <input
                        type="text"
                        value={dateOfBirthMonth}
                        id="dob-field-2"
                        className="profile-dob-input month"
                        onChange={(ev) => {
                          setDateOfBirthMonth(ev.target.value)
                          if (ev.target.value.length === 2) document.getElementById("dob-field-3").focus()
                        }}
                        pattern={"^[0-9]{2}$"}
                        maxLength={2}
                        title="Please enter a valid month e.g. 06"
                      />
                      /
                      <input
                        type="text"
                        value={dateOfBirthYear}
                        className="profile-dob-input year"
                        id="dob-field-3"
                        onChange={(ev) => {
                          setDateOfBirthYear(ev.target.value)
                        }}
                        pattern={"^[0-9]{4}$"}
                        maxLength={4}
                        title="Please enter a valid year e.g. 1975"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-form-element-container">
                <label htmlFor="address-input">Current address</label>
                <input
                  id="address-input"
                  type="text"
                  value={address}
                  onChange={(ev) => {
                    setAddress(ev.target.value)
                  }}
                />
              </div>
              <div className="account-form-buttons-container">
                <div
                  className="account-cancel-changes link"
                  onClick={() => {
                    CancelUpdate()
                  }}
                >
                  Cancel
                </div>
                <input
                  type="submit"
                  value="Save changes"
                  className="add-document-button"
                  disabled={
                    !email ||
                    !firstName ||
                    !lastName ||
                    !dateOfBirthDay ||
                    !dateOfBirthMonth ||
                    !dateOfBirthYear ||
                    !mobileNumber
                  }
                />
              </div>
            </div>
          </form>
        </div>
        <div className="profile-membership-container">
          <div className="profile-membership-details-title">Membership and billing details</div>
          <div className="profile-membership-content-container">
            <div className="profile-membership-type-container">
              <div className="profile-membership-type-label">Membership:</div>
              <div className="profile-membership-type">{plus ? "Plus" : "Free"}</div>
            </div>
            {plus ? (
              <div className="profile-plus-payment-information link" onClick={(_ev) => CreatePortalSession()}>
                Manage payment information
              </div>
            ) : null}
          </div>
          <div className="profile-membership-button-container">
            {!plus ? (
              <button type="button" className="profile-plus-upgrade link" onClick={(_ev) => setShowUpgradeDialog(true)}>
                Upgrade to Plus
              </button>
            ) : null}
          </div>
        </div>
        <div className="security-form-container">
          <form name="security-form">
            <div className="security-details-title">Security</div>
            <div className="security-form-container">
              <div className="security-form-buttons-container">
                <div type="submit" onClick={handleSecuritySubmit} className="add-document-button">
                  {hasMfa ? "Disable 2FA" : "Enable 2FA"}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="tables-column flex-column">
          <TransactionsTable
            tableName="Banking removed from your Executor report"
            className="financials-table"
            transactions={hiddenTransactions && hiddenTransactions}
            refreshTransactions={getTransactions.bind(this)}
            hiddenTransactionTable={true}
          />
        </div>
      </div>
      {showInfoDialog ? (
        <GenericDialog
          title="User Profile"
          heading="Updating profile"
          text={["Successful."]}
          buttons={okButton}
          result={handleOKDialogResult.bind(this)}
        />
      ) : null}
      {showErrorDialog ? (
        <GenericDialog
          title="User Profile - Error"
          text={error}
          buttons={okButton}
          result={handleOKDialogResult.bind(this)}
        />
      ) : null}
      {showUpgradeDialog ? (
        <PlusUpgradeBanner createSession={CreateCheckOutSession.bind(this)} toggle={ToggleUpgradeBanner.bind(this)} />
      ) : null}
    </div>
  )
}

export default Profile
