import * as pdfjsLib from "pdfjs-dist/webpack"

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

/**
 *
 * @param {string} base64 string rep of a file
 * @returns {Promise<HTMLImageElement>}
 */
export const b64ToImage = (base64) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    const type = mimeType(base64)
    img.src = `data:${type};base64,${base64}`
  })
}

/**
 * Enum for MIME types.
 * @readonly
 * @enum {string}
 */
export const MimeType = {
  JPEG: "image/jpeg",
  PNG: "image/png",
  PDF: "application/pdf",
}

/**
 * Determines the MIME type of a given base64 encoded file.
 *
 * @param {string} file - The base64 encoded file string.
 * @returns {MimeType} - The MIME type of the file. Returns MimeType.JPEG if the file starts with "/9j/",
 *                       MimeType.PNG if it starts with "iVBOR", and MimeType.PDF otherwise.
 */
export const mimeType = (file) => {
  if (String(file).startsWith("/9j/")) {
    return MimeType.JPEG
  }
  if (String(file).startsWith("iVBOR")) {
    return MimeType.PNG
  }
  return MimeType.PDF
}

export const imgToImagesFormat = async (base64) => {
  const img = await b64ToImage(base64)
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  canvas.width = img.width
  canvas.height = img.height
  context.drawImage(img, 0, 0)

  return [
    {
      src: canvas.toDataURL("image/png"),
      width: canvas.width,
      height: canvas.height,
      aspectRatio: canvas.width / canvas.height,
    },
  ]
}

export const pdfToImages = async (pdfData) => {
  const loadingTask = pdfjsLib.getDocument({ data: pdfData })
  const pdf = await loadingTask.promise
  const numPages = pdf.numPages
  const images = []

  for (let i = 1; i <= numPages; i++) {
    const page = await pdf.getPage(i)
    const viewport = page.getViewport({ scale: 1 })
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")

    // Adjust canvas dimensions to preserve aspect ratio and rotate
    const width = viewport.width
    const height = viewport.height
    canvas.width = height // Swap width and height to account for rotation
    canvas.height = width

    // Rotate the canvas context
    context.translate(canvas.width / 2, canvas.height / 2)
    context.rotate((90 * Math.PI) / 180) // Rotate 90 degrees clockwise
    context.translate(-canvas.height / 2, -canvas.width / 2) // position in the middle

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }
    await page.render(renderContext).promise
    images.push({
      src: canvas.toDataURL("image/png"),
      width: canvas.width,
      height: canvas.height,
      aspectRatio: canvas.width / canvas.height,
    })
  }

  return images
}

export const processDocuments = async (documents) => {
  return await Promise.all(
    documents.map(async (document) => {
      const file = document?.file ? document.file : null
      let images = []
      if (file) {
        const type = mimeType(file)
        if (type === "application/pdf") {
          images = await pdfToImages(base64ToArrayBuffer(file))
        } else {
          images = await imgToImagesFormat(file)
        }
      }
      return { ...document, images }
    }),
  )
}

export const preprocessDocuments = async (docGroups) => {
  const processedDocGroups = await Promise.all(
    docGroups.map(async (group) => {
      const processedDocuments = await processDocuments(group.documents)
      return { ...group, documents: processedDocuments }
    }),
  )
  return processedDocGroups
}

export const createDocGroups = (documents, doc_chunk_size = 6) => {
  if (!documents || documents.length === 0) return []

  const doc_group_size = Math.ceil(documents.length / doc_chunk_size)
  const doc_groups = Array.from({ length: doc_group_size }, () => ({ documents: [] }))

  let i = 0

  // First page with introduction text
  documents.slice(0, Math.min(doc_chunk_size - 2, documents.length)).forEach((document) => {
    doc_groups[i].documents.push(document)
  })

  // Continued pages with documents only
  if (documents.length > doc_chunk_size - 2) {
    i++
    documents.slice(doc_chunk_size - 2).forEach((document, idx) => {
      if (idx % doc_chunk_size === 0 && idx !== 0) {
        i++
      }
      if (i < doc_group_size) {
        doc_groups[i].documents.push(document)
      }
    })
  }

  return doc_groups
}
