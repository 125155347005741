import transaction_placeholder from "../assets/transaction-placeholder.webp"
import dateUtils from "../utils/dateUtils"
import currencyUtils from "../utils/currencyUtils"
import { useEffect, useState, useRef } from "react"

import AddOutgoingForm from "./AddOutgoingForm"
import TransactionDetailsDialogue from "./TransactionDetailsDialogue"
import TransactionHideConfirmation from "./TransactionHideConfirmation"
import TransactionUnhideConfirmation from "./TransactionUnhideConfirmation"
import outgoings_api from "../api/outgoings"

import refresh from "../assets/refresh.webp"

export function parseAmount(transaction) {
  if (typeof transaction.amount === "number") {
    return transaction.amount
  }
  if (typeof transaction.amount === "string") {
    return Number.parseFloat(transaction.amount)
  }
  if (
    typeof transaction.amount === "object" &&
    transaction.amount !== null &&
    transaction.amount !== undefined &&
    "value" in transaction.amount &&
    "currency" in transaction.amount
  ) {
    return Number.parseFloat(transaction.amount.value)
  }
  throw new TypeError("Transaction amount is not a number, string or object with value and currency fields")
}

export function getTransactionAmount(transaction) {
  const amount = parseAmount(transaction)
  const currency = transaction?.currency ?? transaction?.amount?.currency ?? "GBP"
  return currencyUtils.formatCurrencyString(currency, amount)
}

export function getMerchantLogoUrl(transaction) {
  if (transaction.merchant_logo && transaction.merchant_logo !== "") {
    return transaction.merchant_logo
  }

  if ("enrichments" in transaction) {
    if (
      "merchant" in transaction.enrichments &&
      transaction.enrichments.merchant !== null &&
      "logo" in transaction.enrichments.merchant
    ) {
      return transaction.enrichments.merchant.logo
    }
    if (
      "processor" in transaction.enrichments &&
      transaction.enrichments.processor !== null &&
      "logo" in transaction.enrichments.processor
    ) {
      return transaction.enrichments.processor.logo
    }
  }
  return transaction_placeholder
}
/**
 *
 * @param {*} transaction
 * @returns string
 */
export function getMerchantName(transaction) {
  if (transaction?.merchant_name !== undefined && transaction?.merchant_name !== "") {
    return transaction.merchant_name
  }

  if ("enrichments" in transaction) {
    if (
      "merchant" in transaction.enrichments &&
      transaction.enrichments.merchant !== null &&
      "display_name" in transaction.enrichments.merchant
    ) {
      return transaction.enrichments.merchant.display_name
    }
    if (
      "processor" in transaction.enrichments &&
      transaction.enrichments.processor !== null &&
      "display_name" in transaction.enrichments.processor
    ) {
      return transaction.enrichments.processor.display_name
    }
  }
  return transaction?.transaction_description ?? transaction?.description
}

export function getTransactionLastDate(transaction) {
  if (transaction.date) {
    return dateUtils.formatDateLong(transaction.date)
  }
  if ("date_time" in transaction && transaction.date_time) {
    return dateUtils.formatDateLong(transaction.date_time)
  }
  return "** ******* ****"
}

const TransactionsTable = (props) => {
  const tableName = props.tableName
  const refreshTransactions = props.refreshTransactions
  const tableKey = props.tableKey

  let hiddenTransactionTable = false
  if (props.hiddenTransactionTable === true) hiddenTransactionTable = true

  const [showTransactionTableDetails, setShowTransactionTableDetails] = useState(true)

  const groupByVendor = tableKey === "regular_transactions"
  const [transactions, setTransactions] = useState()
  const [accounts, setAccounts] = useState()

  const [display, setDisplay] = useState(false)

  const [optionsDialogueConfig, setOptionsDialogueConfig] = useState()
  const [selectedTransaction, setSelectedTransaction] = useState()

  const [displayOptionsDialogue, setDisplayOptionsDialogue] = useState(false)
  const [displayHideDialogue, setDisplayHideDialogue] = useState(false)
  const [displayUnhideDialogue, setDisplayUnhideDialogue] = useState(false)
  const [displayDetailsDialogue, setDisplayDetailsDialogue] = useState(false)

  useEffect(() => {
    if (!props.accounts) return
    setAccounts(props.accounts)
  }, [props.accounts])

  useEffect(() => {
    if (!props.transactions) return
    if (groupByVendor) {
      const groupedTransactions = new Map()
      props.transactions.forEach((transaction) => {
        const merchantName = getMerchantName(transaction).toLowerCase().trim()
        if (groupedTransactions.has(merchantName)) {
          groupedTransactions.get(merchantName).push(transaction)
        } else {
          groupedTransactions.set(merchantName, [transaction])
        }
      })
      groupedTransactions.forEach((transactions, _vendor) => {
        transactions.sort((a, b) => {
          return new Date(b.date_time) - new Date(a.date_time)
        })
      })
      setTransactions(groupedTransactions)
    } else {
      setTransactions(props.transactions)
    }
    if (props.hiddenTransactionTable) setShowTransactionTableDetails(false)
  }, [props.transactions, groupByVendor, props.hiddenTransactionTable])

  const loadMoreTransactions = () => {
    if (!props.loadMore) return
    props.loadMore()
  }

  const UpdateDisplay = (ev) => {
    if (ev.target.id !== "toggle") return
    setDisplay(!display)
  }

  const detailsDialogRef = useRef(null)
  useEffect(() => {
    const handleClickOutsideForDetailsDialog = (event) => {
      if (detailsDialogRef.current && !detailsDialogRef.current.contains(event.target)) {
        setDisplayOptionsDialogue(false)
      }
    }
    const handleScrollAway = () => {
      setDisplayOptionsDialogue(false)
    }

    const scrollContainer = document.getElementById("scroll-container")
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScrollAway)
    }

    document.addEventListener("mousedown", handleClickOutsideForDetailsDialog)
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForDetailsDialog)
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScrollAway)
      }
    }
  }, [])

  const openDetailsDialog = (regular_transaction) => {
    const button = document.getElementById(regular_transaction?.label ?? regular_transaction?.id)

    const posTop = button ? button.offsetTop - document.getElementById("scroll-container").scrollTop : 500
    const posLeft = button ? button.offsetLeft : 500

    setOptionsDialogueConfig({ top: `${posTop}px`, left: `${posLeft - 170}px` })
    setSelectedTransaction(regular_transaction)
    setDisplayOptionsDialogue(true)
  }

  const HandleHideTransaction = (_ev) => {
    if (!selectedTransaction) return
    const payload = {
      userId: selectedTransaction.user_id,
      transactionId: selectedTransaction.transaction_id,
      transactionType: selectedTransaction.transaction_type,
      isHidden: true,
    }
    const response = outgoings_api.hideTransaction(payload)
    response
      .then(() => {
        refreshTransactions({})
      })
      .catch((err) => {
        console.error("Handle Hide transaction ", err)
      })
    setDisplayHideDialogue(false)
  }

  const HandleUnhideTransaction = (_ev) => {
    if (!selectedTransaction) return
    const payload = {
      userId: selectedTransaction.user_id,
      transactionId: selectedTransaction.transaction_id,
      transactionType: selectedTransaction.transaction_type,
      isHidden: false,
    }
    const response = outgoings_api.unhideTransaction(payload)
    response
      .then(() => {
        refreshTransactions()
      })
      .catch((err) => {
        console.error("Handle Unhide Transactions", err)
      })
    setDisplayUnhideDialogue(false)
  }

  const HandleOpenDetailsDialogue = (ev) => {
    if (ev.target.id !== "details") return
    setDisplayOptionsDialogue(false)
    setDisplayDetailsDialogue(true)
  }

  const HandleOpenHideDialogue = (ev) => {
    if (ev.target.id !== "hide") return
    setDisplayOptionsDialogue(false)
    setDisplayHideDialogue(true)
  }

  const HandleCloseDetailsDialogue = (ev) => {
    if (ev.target.id !== "close") return
    setDisplayDetailsDialogue(false)
  }

  const HandleCloseHideDialogue = () => {
    setDisplayHideDialogue(false)
  }

  const HandleCloseUnhideDialogue = (_ev) => {
    setDisplayUnhideDialogue(false)
  }

  const openUnhideDialog = (regular_transaction) => {
    setSelectedTransaction(regular_transaction)
    setDisplayUnhideDialogue(true)
  }

  const returnStarPayee = (transaction) => {
    const merchant = getMerchantName(transaction)
    return `${String(merchant).slice(0, 5)}*********`
  }

  const returnStarLastDate = () => {
    return "** ******* ****"
  }

  const returnStarBank = () => {
    return "****"
  }

  const toggleSeeData = (_ev) => {
    // if (ev.target.id !== "toggle") return
    setShowTransactionTableDetails(!showTransactionTableDetails)
  }
  const calculateTotalOutgoings = (transactions) => {
    if (Array.isArray(transactions)) {
      return transactions
        .reduce((total, transaction) => {
          const tAmount = parseAmount(transaction)
          return total + tAmount
        }, 0)
        .toFixed(2)
    }
    return Array.from(transactions)
      .reduce((total, [_vendor, tx]) => {
        const txAmount = tx.reduce((subTotal, innerTx) => {
          return subTotal + parseAmount(innerTx)
        }, 0)
        return total + txAmount
      }, 0)
      .toFixed(2)
  }

  return (
    <>
      {displayOptionsDialogue ? (
        <div className="transaction-table-dialogue" ref={detailsDialogRef} style={optionsDialogueConfig}>
          <div className="transaction-table-dialogue-buttons">
            <button type="button" onClick={HandleOpenDetailsDialogue} id="details">
              More Details
            </button>
            <button type="button" onClick={HandleOpenHideDialogue} id="hide">
              Hide record
            </button>
          </div>
        </div>
      ) : null}
      <div key={tableKey} className="transaction-table-element-container" id={"scroll-event-holder"}>
        <div className="transaction-table-title-container">
          <h2 className="transaction-table-title">{tableName}</h2>
          {hiddenTransactionTable ? (
            <button type="button" className="btn btn-outline-info clear-button " id="toggle" onClick={toggleSeeData}>
              <i className={showTransactionTableDetails ? "bi bi-eye" : "bi-eye-slash"} />
            </button>
          ) : null}
        </div>

        <div className="transaction-table-container">
          <div className="table-headers-container tr">
            <div className="table-header-cell-container name th">
              <div className="table-header-word"> Payee </div>
            </div>
            <div className="table-header-cell-container date th">
              <div className="table-header-word"> Last date </div>
            </div>
            <div className="table-header-cell-container amount th">
              <div className="table-header-word"> Amount </div>
            </div>
            <div className="table-header-cell-container bank th">
              <div className="table-header-word"> Bank </div>
            </div>
          </div>
          <div className="table-rows-container">
            {transactions && Array.isArray(transactions)
              ? transactions.map((regular_transaction, _idx) => {
                  return (
                    <div key={Math.random()} className="table-row-container">
                      <div className="table-body-cell name">
                        <img
                          alt={`${
                            showTransactionTableDetails
                              ? getMerchantName(regular_transaction)
                              : returnStarPayee(regular_transaction)
                          }`}
                          src={getMerchantLogoUrl(regular_transaction)}
                          style={{ width: "40px !important" }}
                        />
                        <div className="table-body-cell-name">
                          {showTransactionTableDetails
                            ? getMerchantName(regular_transaction)
                            : returnStarPayee(regular_transaction)}
                        </div>
                      </div>

                      <div className="table-body-cell date">
                        <div className="table-body-cell-date">
                          {showTransactionTableDetails
                            ? getTransactionLastDate(regular_transaction)
                            : returnStarLastDate()}
                        </div>
                      </div>

                      <div className="table-body-cell amount">
                        <div className="table-body-cell-amount">
                          {showTransactionTableDetails ? getTransactionAmount(regular_transaction) : "****"}
                        </div>
                      </div>

                      <div className="table-body-cell bank">
                        <div className="table-body-cell-bank">
                          {showTransactionTableDetails ? regular_transaction.provider : returnStarBank()}
                        </div>
                      </div>
                      {!hiddenTransactionTable ? (
                        <div
                          className="table-body-cell more"
                          id={regular_transaction?.label ?? regular_transaction?.id}
                          onClick={() => {
                            openDetailsDialog(regular_transaction)
                          }}
                        />
                      ) : (
                        <div
                          className="table-body-cell refresh link"
                          id={regular_transaction?.label ?? regular_transaction?.id}
                          onClick={() => {
                            openUnhideDialog(regular_transaction)
                          }}
                        >
                          <img alt="refresh symbol" src={refresh} />
                        </div>
                      )}
                    </div>
                  )
                })
              : transactions &&
                Array.from(transactions).map(([vendor, tx], idx) => {
                  return (
                    <>
                      <div
                        key={vendor}
                        type="button"
                        className="table-row-container"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${idx}`}
                        aria-expanded="false"
                        aria-controls={vendor}
                        onClick={(event) => {
                          const moreId = tx[0]?.label ?? tx[0]?.id
                          const eId = Number.parseInt(event.target.id)
                          if (moreId === eId) {
                            return
                          }
                          const collapse = document.getElementById(vendor)
                          const chevron = document.getElementById(`${vendor}-chevron`)
                          if (!chevron || !collapse) return
                          if (collapse.classList.contains("show")) {
                            collapse.classList.remove("show")
                            chevron.classList.remove("bi-chevron-compact-up")
                            chevron.classList.add("bi-chevron-compact-down")
                          } else {
                            collapse.classList.add("show")
                            chevron.classList.add("bi-chevron-compact-up")
                            chevron.classList.remove("bi-chevron-compact-down")
                          }
                        }}
                      >
                        <div className="table-body-cell name">
                          {tx.length > 1 ? (
                            <i
                              id={`${vendor}-chevron`}
                              style={{ paddingRight: "0.5rem" }}
                              className="bi bi-chevron-compact-down"
                            />
                          ) : (
                            <i id={`${vendor}-circle`} style={{ paddingRight: "0.5rem" }} className="bi bi-circle" />
                          )}
                          <img
                            alt="merchant logo"
                            src={getMerchantLogoUrl(tx[0])}
                            style={{ width: "40px !important" }}
                          />
                          <div className="table-body-cell-name">
                            {showTransactionTableDetails ? getMerchantName(tx[0]) : returnStarPayee(tx[0])}
                          </div>
                        </div>

                        <div className="table-body-cell date">
                          <div className="table-body-cell-date">
                            {showTransactionTableDetails ? getTransactionLastDate(tx[0]) : returnStarLastDate()}
                          </div>
                        </div>

                        <div className="table-body-cell amount">
                          <div className="table-body-cell-amount">
                            {showTransactionTableDetails ? getTransactionAmount(tx[0]) : "****"}
                          </div>
                        </div>

                        <div className="table-body-cell bank">
                          <div className="table-body-cell-bank">
                            {showTransactionTableDetails ? tx[0].provider : returnStarBank()}
                          </div>
                        </div>
                        {!hiddenTransactionTable ? (
                          <div
                            className="table-body-cell more"
                            id={tx[0]?.label ?? tx[0]?.id}
                            onClick={() => {
                              openDetailsDialog(tx[0])
                            }}
                          />
                        ) : (
                          <div
                            className="table-body-cell refresh link"
                            id={tx[0]?.label ?? tx[0]?.id}
                            onClick={() => {
                              openUnhideDialog(tx[0])
                            }}
                          >
                            <img alt="refresh symbol" src={refresh} />
                          </div>
                        )}
                      </div>

                      {tx.length > 1 ? (
                        <div key={Math.random()} className="collapse" id={vendor}>
                          <div className="card expanse">
                            {tx.slice(1).map((innerTx, _idx) => {
                              return (
                                <div key={Math.random()} className="table-row-container">
                                  <div className="table-body-cell name">
                                    <div className="table-body-cell-name">
                                      {showTransactionTableDetails
                                        ? getMerchantName(innerTx)
                                        : returnStarPayee(innerTx)}
                                    </div>
                                  </div>

                                  <div className="table-body-cell date">
                                    <div className="table-body-cell-date">
                                      {showTransactionTableDetails
                                        ? getTransactionLastDate(innerTx)
                                        : returnStarLastDate()}
                                    </div>
                                  </div>

                                  <div className="table-body-cell amount">
                                    <div className="table-body-cell-amount">
                                      {showTransactionTableDetails ? getTransactionAmount(innerTx) : "****"}
                                    </div>
                                  </div>

                                  <div className="table-body-cell bank">
                                    <div className="table-body-cell-bank">
                                      {showTransactionTableDetails ? innerTx.provider : returnStarBank()}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}
                    </>
                  )
                })}
          </div>
        </div>
        {props.loadMore ? (
          <button
            type="button"
            className="btn btn-primary"
            style={{ margin: "0 auto", display: "block" }}
            onClick={loadMoreTransactions}
          >
            Load More Transactions
          </button>
        ) : null}
        {transactions ? (
          Array.isArray(transactions) && !hiddenTransactionTable ? (
            <div className="transaction-table-total-amount-container">
              <div className="transaction-table-total-amount-label">
                Total Outgoings: £ {calculateTotalOutgoings(transactions)}
              </div>
            </div>
          ) : (
            <div className="transaction-table-total-amount-container">
              <div className="transaction-table-total-amount-label">
                Total Outgoings: £ {calculateTotalOutgoings(transactions)}
              </div>
            </div>
          )
        ) : null}
        {/*
          !hiddenTransactionTable ? (
          <div className="transaction-table-something-missing-container">
            <div className="transaction-table-something-missing-label">Something missing?</div>
            <div className="transaction-table-something-missing-add" id="toggle" onClick={UpdateDisplay}>
              Add outgoing
            </div>
          </div>
        ) : null */}
      </div>
      {!hiddenTransactionTable ? (
        <>
          <AddOutgoingForm
            display={display}
            updateDisplay={UpdateDisplay.bind(this)}
            accounts={accounts}
            refreshTransactions={refreshTransactions}
          />
          <TransactionDetailsDialogue
            display={displayDetailsDialogue}
            closeDisplay={HandleCloseDetailsDialogue.bind(this)}
            transaction={selectedTransaction}
          />
          <TransactionHideConfirmation
            display={displayHideDialogue}
            closeDisplay={HandleCloseHideDialogue.bind(this)}
            hideTransaction={HandleHideTransaction.bind(this)}
          />
        </>
      ) : (
        <>
          <TransactionUnhideConfirmation
            display={displayUnhideDialogue}
            closeDisplay={HandleCloseUnhideDialogue.bind(this)}
            unhideTransaction={HandleUnhideTransaction.bind(this)}
          />
        </>
      )}
    </>
  )
}

export default TransactionsTable
