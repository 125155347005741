import { BrowserRouter as Router } from "react-router-dom"
import "./App.css"
import AppLayout from "./containers/AppLayout"

function App() {
  return (
    <Router>
      <AppLayout />
    </Router>
  )
}

export default App
