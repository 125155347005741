import http_api from "./http/http_api"

const createCheckoutSession = (type, success, fail) =>
  http_api.post(
    `/44968d3ddb237d5c8c9320019/create/checkout_session/${type}/${encodeURIComponent(success)}/${encodeURIComponent(fail)}`,
  )
const createPortalSession = (return_url) =>
  http_api.post(`/44968d3ddb237d5c8c9320019/create/portal_session/${encodeURIComponent(return_url)}`)

export default {
  createCheckoutSession,
  createPortalSession,
}
