import { useEffect, useState } from "react"
import Themes from "../models/Themes"
import { Navigate, Route, Routes } from "react-router-dom"

import Home from "../pages/Public/Home"
import Blog from "../pages/Public/Blog"
import BlogArticle from "../pages/Public/BlogArticle"

import Profile from "../pages/App/Profile"
import Dashboard from "../pages/App/Dashboard"
import DocumentsSection from "../pages/App/DocumentsSection"
import Banking from "../pages/App/Banking"
import Contacts from "../pages/App/Contacts"
import Reports from "../pages/App/Reports"
import SharedWithYou from "../pages/App/SharedWithYou"

import Login from "../pages/Public/Login"
import Register from "../pages/Public/Register"
import KnowledgeHub from "../pages/App/KnowledgeHub"
import GuideArticle from "../pages/App/GuideArticle"
import Executors from "../pages/App/Executors"
import Privacy from "../pages/App/Privacy"
import HeaderContainer from "./HeaderContainer"
import Verify2FA from "../pages/Public/Verify2FA"
import UpdateMFA from "../pages/App/UpdateMFA"
import ForgotPassword from "../pages/Public/ForgotPassword"
import ResetPassword from "../pages/Public/ResetPassword"

const AppLayout = () => {
  const [theme, setTheme] = useState()

  useEffect(() => {
    let current_theme = theme
    if (!current_theme) {
      const th = localStorage.getItem("theme")
      if (th) {
        setTheme(Number.parseInt(th))
        current_theme = Number.parseInt(th)
      } else {
        setTheme(Themes.THEME_DARK)
        current_theme = Themes.THEME_DARK
      }
    }
    const head = document.head
    const link = document.createElement("link")

    link.type = "text/css"
    link.rel = "stylesheet"

    if (current_theme === Themes.THEME_DARK) link.href = `${window.location.origin}/theme_dark.css`
    else link.href = `${window.location.origin}/theme_light.css`

    head.appendChild(link)

    return () => {
      head.removeChild(link)
    }
  }, [theme])

  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css" />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <Routes>
        <Route exact path="/" element={<Navigate to="/dashboard" />} />

        <Route path="/home" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/article">
          <Route path=":link" element={<BlogArticle />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify2fa" element={<Verify2FA />} />
        <Route path="/enablemfa" element={<UpdateMFA enable={true} />} />
        <Route path="/disablemfa" element={<UpdateMFA enable={false} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/dashboard"
          element={
            <>
              <HeaderContainer />
              <Dashboard />
            </>
          }
        />
        <Route path="/document">
          <Route
            path=":type"
            element={
              <>
                <HeaderContainer />
                <DocumentsSection />
              </>
            }
          />
        </Route>
        <Route
          path="/banking"
          element={
            <>
              <HeaderContainer />
              <Banking />
            </>
          }
        />
        <Route
          path="/contacts"
          element={
            <>
              <HeaderContainer />
              <Contacts />
            </>
          }
        />
        <Route
          path="/executors"
          element={
            <>
              <HeaderContainer />
              <Executors />
            </>
          }
        />
        <Route
          path="/reports"
          element={
            <>
              <HeaderContainer />
              <Reports />
            </>
          }
        />
        <Route
          path="/shared-with-you"
          element={
            <>
              <HeaderContainer />
              <SharedWithYou />
            </>
          }
        />

        <Route
          path="/profile"
          element={
            <>
              <HeaderContainer />
              <Profile />
            </>
          }
        />
        <Route
          path="/knowledge-hub"
          element={
            <>
              <HeaderContainer />
              <KnowledgeHub />
            </>
          }
        />
        <Route path="/guide">
          <Route
            path=":link"
            element={
              <>
                <HeaderContainer />
                <GuideArticle />
              </>
            }
          />
        </Route>
        <Route
          path="/privacy"
          element={
            <>
              <HeaderContainer />
              <Privacy />
            </>
          }
        />
      </Routes>
    </>
  )
}

export default AppLayout
