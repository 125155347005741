import isa from "../assets/documents/assets/isa.svg"
import property from "../assets/documents/assets/property.svg"
import bonds from "../assets/documents/assets/savings-bond.svg"
import pension from "../assets/documents/assets/pension.svg"
import shares from "../assets/documents/assets/shares.svg"
import premiumBonds from "../assets/documents/assets/premium-bonds.svg"
import crypto from "../assets/documents/assets/crypto.svg"
import otherAssets from "../assets/documents/assets/other.svg"

import will from "../assets/documents/future/will.svg"
import letter_of_wishes from "../assets/documents/future/letter-of-wishes.svg"
import organ_donation from "../assets/documents/future/organ-donation.svg"
import power_of_attorney from "../assets/documents/future/power-of-attorney.svg"
import trusts from "../assets/documents/future/trusts.svg"
import passwordVault from "../assets/documents/future/password-vault.svg"
import driversLicence from "../assets/documents/future/drivers-license.svg"
import passport from "../assets/documents/future/passport.svg"

import health_insurance from "../assets/documents/insurance/private-medical.svg"
import life_insurance from "../assets/documents/insurance/life-insurance.svg"
import other_insurance from "../assets/documents/insurance/other-insurance.svg"
import criticalIllness from "../assets/documents/insurance/critical-illness.svg"
import deathInService from "../assets/documents/insurance/death-in-service.svg"
import incomeProtection from "../assets/documents/insurance/income-protection.svg"
import disability from "../assets/documents/insurance/disability.svg"

// * went into the futures folder
// import id from "../assets/documents/other/id.svg" // ie passport
// import passwords from "../assets/documents/other/passwords.svg" // ie password vault

// * Assets and Liabilities
const ISA = {
  title: "ISA",
  word: "ISA",
  description:
    "An ISA will end when either, your executor closes it or the administration of your estate " +
    "is completed. Otherwise, your ISA provider will close your ISA 3 years and 1 day after you die. " +
    "There will be no Income Tax or Capital Gains Tax to pay up to that date, but ISA investments " +
    "will form part of your estate for Inheritance Tax purposes.",
  image: isa,
  link: "isa",
}

const PROPERTY = {
  title: "Property",
  word: "Property",
  description:
    "Property is counted as part of your estate. There is a ‘main residence nil-rate band’ rule. This " +
    "provides an extra tax-free allowance of £175,000 on top of the £325,000 nil-rate band making the total " +
    "£500,000, provided that the house is being left to a direct descendent such as child, step-child, " +
    "foster child, adopted child or grandchild.",
  image: property,
  link: "property",
}

const SAVINGS_BONDS = {
  title: "Savings Bonds",
  word: "Savings Bond",
  description:
    "Fixed-rate savings bonds are interest-paying savings accounts offered by banks and building " +
    "societies for a fixed amount of time. If the Fixed Rate Bond is held in your sole name, it can be closed it " +
    "early and the interest that's built up paid out. It can also be kept open until it reaches the end of its fixed" +
    " term. If kept open, the account has to be left in your name.",
  image: bonds,
  link: "savings-bonds",
}

const PREMIUM_BONDS = {
  title: "Premium Bonds",
  word: "Premium Bond",
  description:
    "Premium Bonds are an investment product issued by National Savings and Investment. Unlike " +
    "other investments, where you earn interest or a regular dividend income, you are entered into a monthly prize " +
    "draw where you can win between £25 and £1 million tax free. NS&I does allow them to be held by the estate " +
    "for one year after death and during this time they will still be entered into the prize draw each month.",
  image: premiumBonds,
  link: "premium-bonds",
}

const OTHER_ASSETS = {
  title: "Other",
  word: "Other",
  description:
    "This form may be used for logging any other assets. These may include Jewellery, vehicles, " +
    "Artworks, Coin collections, in fact any other asset that you wish to be known to your executors and whom you " +
    "might want to bequeath it to. (The bequest would need to be specifically entered into " +
    "the will to be legally binding)",
  image: otherAssets,
  link: "other-assets",
}

const PENSION = {
  title: "Pension",
  word: "Pension",
  description:
    "Adding pension information will help your executors get the pension benefit quickly to those you " +
    "wish to leave it to. Defined benefit pensions usually provide a pension income to a partner, or another " +
    "financial dependant, after you die. Beneficiaries can be nominated.",
  image: pension,
  link: "pension",
}

const SHARES = {
  title: "Shares",
  word: "Share",
  description:
    "Did you know? Under the default ‘Articles of association’ rules, the person who is left the shares " +
    "can decide to become a shareholder or transfer them to someone else. During the period the person who has been " +
    "left the shares is entitled to the benefits of those shares, such as dividends, " +
    "but is not entitled to vote as a shareholder.",
  image: shares,
  link: "shares",
}

const CRYPTO = {
  title: "Crypto",
  word: "Crypto",
  description:
    "Your cryptocurrency is an asset that forms part of your estate. cryptocurrency is not " +
    "automatically transferred upon death, the person’s executor would need to access the crypto so it is " +
    "essential that relevant access codes are stored securely where an executor can retrieve them. Despite the " +
    "advanced technology behind cryptocurrency, the method for distributing a person’s " +
    "cryptocurrency remains old-school.",
  image: crypto,
  link: "crypto",
}

const WILL = {
  title: "Will",
  word: "Will",
  description:
    "Creating a will is one of the most important steps in planning for the future and ensuring your loved ones are taken care of. A will outlines your wishes for how your assets and possessions are distributed after you pass away. It allows you to appoint executors who will manage your estate and fulfill your instructions. Without a valid will, the law determines how your estate is divided, which might not align with your intentions. By including your will in Snapshot, you ensure it’s securely stored and accessible to the right people, giving you peace of mind that your wishes will be respected.",
  image: will,
  link: "will",
}

const LETTER_OF_WISHES = {
  title: "Letter of Wishes",
  word: "Letter of Wishes",
  description:
    "A Letter of Wishes is a personal document that complements your will, offering guidance to your executors and loved ones about your preferences that may not be legally binding. It can include specific instructions, such as how you’d like sentimental items distributed, the care of pets, or even your hopes for the upbringing of children. While not a formal legal document, it provides clarity and helps ensure that your personal values and intentions are honored. Adding your Letter of Wishes to Snapshot ensures it’s securely stored and easily accessible when it’s needed most.",
  image: letter_of_wishes,
  link: "letter-of-wishes",
}

const ORGAN_DONATION = {
  title: "Organ Donation",
  word: "Organ Donation",
  description:
    "Organ donation is a selfless decision to help others by allowing your organs to be used for transplants or medical research after your death. Clearly stating your wishes regarding organ donation ensures that your preferences are respected and can save lives or contribute to advancements in medicine. Including your organ donation decision in Snapshot provides clarity for your loved ones and medical professionals, reducing uncertainty and ensuring your intentions are known and easily accessible when needed.",
  image: organ_donation,
  link: "organ-donation",
}

const POWER_OF_ATTORNEY = {
  title: "Power of Attorney",
  word: "Power of Attorney",
  description:
    "A Power of Attorney is a legal document that allows you to appoint someone you trust to make decisions on your behalf if you are unable to do so. This can include decisions about your finances, property, or healthcare. By having a Power of Attorney in place, you ensure that your wishes are respected and that someone you trust has the authority to act in your best interests during times of incapacity. Adding your Power of Attorney to Snapshot ensures it’s securely stored and accessible to the right people, providing peace of mind and preparedness for the unexpected.",
  image: power_of_attorney,
  link: "power-of-attorney",
}

const TRUSTS = {
  title: "Trusts",
  word: "trust",
  description:
    "A trust is a legal arrangement that allows you to manage and protect assets for the benefit of specific individuals or organizations. Trusts can help ensure your wealth is distributed according to your wishes, provide financial security for loved ones, or reduce inheritance tax liabilities. By setting up a trust, you can maintain control over how and when beneficiaries access the assets, offering greater flexibility and security. Adding details of your trusts to Snapshot ensures your executors and trustees have the necessary information to fulfil your intentions, keeping your plans clear and accessible.",
  image: trusts,
  link: "trusts",
}

const DRIVERS_LICENCE = {
  title: "Driver's Licence",
  word: "Driver's Licence",
  description:
    "Your driving licence is a key document that you may need to access regularly, whether for identification, renewing, or managing vehicle-related tasks. By adding it to Snapshot, you have a secure and organized central hub to store this important information, ensuring it’s always at your fingertips when needed. Additionally, in the event of your passing, having your driving licence details readily accessible can assist your executors or loved ones with tasks such as closing accounts or transferring vehicle ownership, providing clarity and reducing stress during a difficult time.",
  image: driversLicence,
  link: "drivers-licence",
}

const PASSPORT = {
  title: "Passport",
  word: "Passport",
  description:
    "Your passport is a vital document for identification and travel, and keeping it securely stored in Snapshot ensures you have quick access to the essential details whenever needed—whether for travel plans, renewing your passport, or providing proof of identity. Snapshot acts as your central hub for organising important documents, making life easier. Additionally, in the event of your passing, having your passport details accessible can assist loved ones or executors in handling legal and administrative matters with efficiency and care.",
  image: passport,
  link: "passport",
}

const PASSWORDS = {
  title: "Password vault",
  word: "password vault",
  description:
    "A Password Vault is an essential tool for securely managing your online credentials, providing quick and safe access to accounts such as banking, social media, and subscriptions. It centralizes your passwords, reducing the risk of forgetting important login details and ensuring a streamlined way to manage your digital presence. In addition to its everyday convenience, a Password Vault can also help trusted individuals handle your digital accounts efficiently if needed, offering peace of mind and continuity in all situations.",
  image: passwordVault,
  link: "passwords",
}

const PRIVATE_MEDICAL = {
  title: "Private Medical",
  word: "Private Medical",
  description:
    "Private medical details are essential for accessing healthcare services, managing appointments, and ensuring you receive the care you need without delays. Keeping this information organized and easily accessible ensures a smoother process for handling claims, verifying coverage, or updating providers. In addition, having these details readily available can assist trusted individuals in coordinating your care or handling medical matters on your behalf if required, offering peace of mind in both routine and unexpected situations.",
  image: health_insurance,
  link: "private-medical",
}

const LIFE_INSURANCE = {
  title: "Life Insurance",
  word: "life insurance",
  description:
    "Life insurance is a critical financial asset designed to provide security and support for your loved ones. By keeping your policy details organized and accessible, you ensure that beneficiaries can quickly and efficiently access the benefits in times of need. This not only simplifies the claims process but also minimises delays in providing essential financial assistance, offering peace of mind that your plans will be carried out as intended.",
  image: life_insurance,
  link: "life-insurance",
}

const CRITICAL_ILLNESS = {
  title: "Critical Illness",
  word: "Critical Illness",
  description:
    "Critical illness cover provides financial support in the event of a serious health diagnosis, helping to ease the burden of medical expenses, lost income, or lifestyle adjustments. Keeping your policy details well-organized ensures you can quickly access the necessary information to make a claim and receive support when it’s needed most. Additionally, having these details accessible allows trusted individuals to assist with managing the process, giving you and your loved ones added reassurance during challenging times.",
  image: criticalIllness,
  link: "critical-illness",
}

const DEATH_IN_SERVICE = {
  title: "Death in Service",
  word: "Death in Service",
  description:
    "Death in Service is a valuable benefit often provided by employers, offering financial support to your nominated beneficiaries if you pass away while employed. This benefit can provide peace of mind, knowing that your loved ones will receive a lump sum payment to help secure their financial future. Keeping your Death in Service details well-documented ensures that beneficiaries can easily access this support without unnecessary delays, simplifying what can be a challenging time for those you care about.",
  image: deathInService,
  link: "death-in-service",
}

const INCOME_PROTECTION = {
  title: "Income Protection",
  word: "Income Protection",
  description:
    "Income protection is a crucial safeguard that provides financial support if you’re unable to work due to illness or injury. It ensures you have a steady income to cover essential expenses and maintain your standard of living during difficult times. Keeping your income protection policy details organised and easily accessible allows you to quickly initiate claims and manage your coverage effectively. It also ensures trusted individuals can assist if needed, offering peace of mind and stability in unforeseen circumstances.",
  image: incomeProtection,
  link: "income-protection",
}

const DISABILITY = {
  title: "Disability",
  word: "Disability",
  description:
    "Disability coverage provides essential financial protection if you experience a long-term disability that affects your ability to work. This benefit can help replace lost income, cover medical expenses, or assist with living costs, ensuring financial stability during challenging times. Keeping your disability coverage details well-organized and accessible ensures you can quickly file claims and manage benefits when needed. It also allows trusted individuals to step in and assist with the process, offering an added layer of security and peace of mind.",
  image: disability,
  link: "disability",
}

const OTHER_INSURANCE = {
  title: "Other Insurance",
  word: "other insurance",
  description:
    "Other insurance policies, such as home, vehicle, or travel insurance, provide vital protection for various aspects of your life. Ensuring these details are well-organized allows you to access policy information quickly for claims, renewals, or updates, making it easier to stay covered and manage unexpected events. Additionally, having your insurance information readily available ensures that trusted individuals can assist with managing policies or claims if the need arises, offering convenience and peace of mind.",
  image: other_insurance,
  link: "other-insurance",
}

const ASSETS_AND_LIABILITIES = {
  Pension: PENSION,
  "Savings Bonds": SAVINGS_BONDS,
  "Premium Bonds": PREMIUM_BONDS,
  Shares: SHARES,
  ISA: ISA,
  Crypto: CRYPTO,
  Property: PROPERTY,
  Other: OTHER_ASSETS,
}

const FUTURE_PLANNING = {
  Will: WILL,
  "Letter of Wishes": LETTER_OF_WISHES,
  "Power of Attorney": POWER_OF_ATTORNEY,
  "Organ Donation": ORGAN_DONATION,
  Trusts: TRUSTS,
  "Driver's Licence": DRIVERS_LICENCE,
  Passport: PASSPORT,
  Passwords: PASSWORDS,
}

const INSURANCE = {
  "Private Medical": PRIVATE_MEDICAL,
  "Life Insurance": LIFE_INSURANCE,
  "Critical Illness": CRITICAL_ILLNESS,
  "Death In Service": DEATH_IN_SERVICE,
  "Income Protection": INCOME_PROTECTION,
  Disability: DISABILITY,
  "Other Insurance": OTHER_INSURANCE,
}

const LINKS = {
  isa: ISA,
  property: PROPERTY,
  "savings-bonds": SAVINGS_BONDS,
  "premium-bonds": PREMIUM_BONDS,
  pension: PENSION,
  shares: SHARES,
  "other-assets": OTHER_ASSETS,
  crypto: CRYPTO,

  will: WILL,
  "letter-of-wishes": LETTER_OF_WISHES,
  "organ-donation": ORGAN_DONATION,
  "power-of-attorney": POWER_OF_ATTORNEY,
  trusts: TRUSTS,
  "drivers-licence": DRIVERS_LICENCE,
  passport: PASSPORT,
  passwords: PASSWORDS,

  "private-medical": PRIVATE_MEDICAL,
  "life-insurance": LIFE_INSURANCE,
  "critical-illness": CRITICAL_ILLNESS,
  "death-in-service": DEATH_IN_SERVICE,
  "income-protection": INCOME_PROTECTION,
  disability: DISABILITY,
  "other-insurance": OTHER_INSURANCE,
}

export default {
  LINKS,

  ASSETS_AND_LIABILITIES,
  FUTURE_PLANNING,
  INSURANCE,

  ISA,
  PROPERTY,
  SAVINGS_BONDS,
  PREMIUM_BONDS,
  OTHER_ASSETS,
  CRYPTO,
  PENSION,
  SHARES,

  WILL,
  LETTER_OF_WISHES,
  ORGAN_DONATION,
  POWER_OF_ATTORNEY,
  TRUSTS,
  DRIVERS_LICENCE,
  PASSPORT,
  PASSWORDS,

  PRIVATE_MEDICAL,
  LIFE_INSURANCE,
  OTHER_INSURANCE,
  CRITICAL_ILLNESS,
  DEATH_IN_SERVICE,
  INCOME_PROTECTION,
  DISABILITY,
}
