import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import users_api from "../../api/users"
import LoginRegisterSideImage from "../../components/LoginRegisterSideImage"

const Verify2FA = (_props) => {
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const inputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()))
  const verifyButton = useRef(null)

  const handleVerify = (event) => {
    event.preventDefault()
    setError("")
    handleVerifySubmit(event)
  }

  const handleVerifySubmit = (_event) => {
    if (!CheckVerificationData) setError("The data you have inputted is invalid")
    const verificationCode = inputRefs.current.map((v) => v?.current?.value ?? undefined).join("")
    VerifyUserPhone(verificationCode)
  }

  const VerifyUserPhone = (verificationCode) => {
    if (!verificationCode) return
    const formData = {
      apiKey: process.env.REACT_APP_SERVER_API_KEY,
      code: verificationCode,
    }
    const response = users_api.verify2FA(formData)
    response
      .then((response) => {
        if (response.data.error) {
          setMessage("")
          setError(response.data.error)
        } else {
          navigate("/dashboard")
        }
      })
      .catch((_err) => {
        setMessage("")
        setError("Verification 2fa failed")
        //console.error(err)
      })
  }

  const SendUserText = () => {
    setMessage("")
    setError("")
    //setVerificationCharArr(Array.from({ length: 6 }, () => undefined))
    const formData = {
      apiKey: process.env.REACT_APP_SERVER_API_KEY,
    }
    const response = users_api.resend2FA(formData)
    response
      .then((response) => {
        if (response.data && response.data.error) {
          setError("Authentication Code failed to resend")
        } else setMessage("Authentication Code has been resent")
      })
      .catch((_err) => {
        setError("Authentication Code failed to resend")
        //console.errror("auth error", err)
      })
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      inputRefs.current[index - 1].current.focus()
    }
    CheckVerificationData()
  }

  const handleFocus = (index) => {
    inputRefs.current[index].current.select()
  }

  const handlePaste = (e, _index) => {
    const pastedData = e.clipboardData.getData("text/plain")
    const values = pastedData.replace(/[\t\r\n\s]/g, "").split("")

    values.forEach((val, idx) => {
      if (idx < inputRefs.current.length) {
        inputRefs.current[idx].current.value = val
        if (idx < inputRefs.current.length - 1) {
          inputRefs.current[idx + 1].current.focus()
        }
      }
    })
    e.preventDefault()
    CheckVerificationData()
  }

  const CheckVerificationData = () => {
    const validCharsPattern = /[\w\d]{6}/
    const isValid = validCharsPattern.test(inputRefs.current.map((v) => v?.current?.value ?? undefined).join(""))
    if (verifyButton?.current) {
      verifyButton.current.disabled = !isValid
    }
    return isValid
  }

  const handleChange = (e, index) => {
    const { value } = e.target

    if (value.length === 1) {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].current.focus()
      }
    }
    CheckVerificationData()
  }

  return (
    <>
      <div className="login-page-container">
        <LoginRegisterSideImage />
        <div className="mfa-subpage-container">
          <div className="mfa-page-verification-container">
            <form name="mfa-form" onSubmit={handleVerify}>
              <div className="mfa-page-title">Multi-factor Authentication</div>
              <div className="mfa-page-subtitle verification">Mobile verification</div>
              <div className="mfa-page-description verification">
                We’ve sent a 6 character code to your mobile number for authentication purposes. The code will expire
                after 2 minutes.
              </div>
              <div className="verify-form-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <input
                    className="verify-form-character"
                    key={Math.random()}
                    type="text"
                    maxLength={1}
                    ref={inputRefs.current[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={() => handleFocus(index)}
                    onPaste={(e) => handlePaste(e, index)}
                  />
                ))}
              </div>

              <div className="mfa-error-message">{error && error}</div>
              <div className="mfa-message">{message && message}</div>

              <div className="verify-submit-button-container">
                <input
                  type="submit"
                  value="Verify"
                  className="verify-submit-button link"
                  ref={verifyButton}
                  disabled
                  //disabled={!CheckVerificationData()}
                  onClick={handleVerifySubmit}
                />
              </div>

              <div className="verify-resend-code link" onClick={(_ev) => SendUserText(true)}>
                Resend verification code
              </div>
              <div className="return-to-details link" onClick={(_ev) => navigate("/login")}>
                Return to login
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Verify2FA
