import { Link, NavLink } from "react-router-dom"
import tiktok from "../assets/socials/tiktok.webp"
import linkedin from "../assets/socials/linkedin.webp"
import facebook from "../assets/socials/facebook.webp"
import FeedbackPopup from "./FeedbackPopup"
import { useState } from "react"
import ContactUsPopup from "./ContactUsPopup"
import InviteOthersPopUp from "./InviteOthersPopUp"

const SideNav = () => {
  const [showFeedbackPopUp, setShowFeedbackPopUp] = useState(false)
  const [showHelpPopUp, setShowHelpPopUp] = useState(false)
  const [showInvitePopUp, setShowInvitePopUp] = useState(false)

  const [showHelpFeedbackMenu, setShowHelpFeedbackMenu] = useState(false)

  const toggleInvitePopup = () => {
    setShowInvitePopUp(!showInvitePopUp)
    // Hide other popups
    hideSubMenu()
    setShowFeedbackPopUp(false)
    setShowHelpPopUp(false)
  }

  const toggleHelpPopUp = () => {
    setShowHelpPopUp(!showHelpPopUp)
    // Hide other popups
    hideSubMenu()
    setShowFeedbackPopUp(false)
    setShowInvitePopUp(false)
  }

  const toggleFeedbackPopUp = () => {
    setShowFeedbackPopUp(!showFeedbackPopUp)
    // Hide other popups
    hideSubMenu()
    setShowHelpPopUp(false)
    setShowInvitePopUp(false)
  }

  const toggleSubMenuPopUp = () => {
    setShowHelpFeedbackMenu(!showHelpFeedbackMenu)
  }

  const hideSubMenu = () => {
    setShowHelpFeedbackMenu(false)
  }

  return (
    <>
      <div className="side-nav-container">
        <div className="side-nav-main-links-container">
          <NavLink to={"/dashboard"} activeclassname="active" className="side-nav-main-link link">
            Dashboard
          </NavLink>
          <NavLink to={"/banking"} activeclassname="active" className="side-nav-main-link link">
            Banking
          </NavLink>
          <NavLink to={"/contacts"} activeclassname="active" className="side-nav-main-link link">
            Contacts
          </NavLink>
          <NavLink to={"/reports"} activeclassname="active" className="side-nav-main-link link">
            Your Reports
          </NavLink>
        </div>
        <div className="side-nav-other-links-container">
          <div className="side-nav-main-link title link">Sharing</div>
          <NavLink to={"/executors"} activeclassname="active" className="side-nav-sub-link link">
            Your Executors
          </NavLink>
          <NavLink to={"/shared-with-you"} activeclassname="active" className="side-nav-sub-link link">
            Shared with you
          </NavLink>
        </div>
        <div className="side-nav-user-links-container">
          <NavLink to={"/profile"} className="side-nav-sub-link link">
            Profile
          </NavLink>
          {/*<NavLink to={"/knowledge-hub"} className="side-nav-sub-link link">
                        Knowledge Hub
                    </NavLink>
                    */}
          <a
            className="side-nav-sub-link link"
            href="https://snaphost.life/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            Knowledge Hub
          </a>
          <div onClick={toggleFeedbackPopUp} className="side-nav-sub-link link">
            Feedback
          </div>
          <div onClick={toggleHelpPopUp} className="side-nav-sub-link link">
            Contact Us
          </div>
          <NavLink to={"/privacy"} className="side-nav-sub-link link">
            Privacy
          </NavLink>
          <div onClick={toggleInvitePopup} className="side-nav-sub-link link">
            Invite others to join
          </div>
        </div>
        <div className="side-nav-social-links-container">
          <div className="side-nav-socials-container">
            <Link to={"https://www.tiktok.com/@snapshot.life"}>
              <img src={tiktok} alt="TikTok Link" />
            </Link>
            <Link to={"https://www.linkedin.com/company/headstone-life"}>
              <img src={linkedin} alt="LinkedIn Link" />
            </Link>
            <Link to={"https://www.facebook.com/Snapshotsupport"}>
              <img src={facebook} alt="Facebook Link" />
            </Link>
          </div>
        </div>
      </div>
      <div className="side-nav-pop-ups-container">
        {showHelpFeedbackMenu && showHelpFeedbackMenu ? (
          <div className="side-nav-sub-menu-container">
            <div
              className="side-nav-sub-menu-item link"
              onClick={(_ev) => {
                if (!showFeedbackPopUp) toggleFeedbackPopUp()
                else toggleSubMenuPopUp()
              }}
            >
              Feedback
            </div>
            <div
              className="side-nav-sub-menu-item link"
              onClick={(_ev) => {
                if (!showHelpPopUp) toggleHelpPopUp()
                else toggleSubMenuPopUp()
              }}
            >
              Contact us
            </div>
          </div>
        ) : null}
        {showFeedbackPopUp && showFeedbackPopUp ? <FeedbackPopup toggle={toggleFeedbackPopUp.bind(this)} /> : null}
        {showHelpPopUp && showHelpPopUp ? <ContactUsPopup toggle={toggleHelpPopUp.bind(this)} /> : null}
        {showInvitePopUp && showInvitePopUp ? <InviteOthersPopUp toggle={toggleInvitePopup.bind(this)} /> : null}
      </div>
    </>
  )
}

export default SideNav
