import { useEffect, useState } from "react"

import SideNav from "../../components/SideNav"
import createReport from "../../utils/createReport"

import users_api from "../../api/users"
import outgoings_api from "../../api/outgoings"
import contacts_api from "../../api/contacts"
import documents_api from "../../api/documents"

import ExecutorReport from "../../components/ExecutorReport"
import FinancialReport from "../../components/FinancialReport"
import { createDocGroups, preprocessDocuments } from "../../utils/b64PdfProccessers"

const Reports = () => {
  const [executorReport, setExecutorReport] = useState()
  const [financialReport, setFinancialReport] = useState()

  const [transactions, setTransactions] = useState()
  const [contacts, setContacts] = useState()
  const [accounts, setAccounts] = useState()
  const [documents, setDocuments] = useState()
  const [user, setUser] = useState()

  const [reportsMenu, setReportsMenu] = useState()
  const [showSection, setShowSection] = useState(0)

  useEffect(() => {
    const menus = []

    let menu = {}
    menu.name = "Executor Snapshot"
    menu.activeClass = "active link report-menu-item"
    menus.push(menu)

    menu = {}
    menu.name = "Financial Snapshot"
    menu.activeClass = "link report-menu-item"
    menus.push(menu)

    setReportsMenu(menus)
  }, [])

  useEffect(() => {
    const awaitedUser = async () => {
      try {
        const user = await getUser()
        const userId = user.id
        getTransactions({ offset: 0, limit: 10000, transactionTypes: ["loan", "subscription"], userId })
        GetContacts()
        getAccounts()
        GetDocuments()
      } catch (e) {
        console.error("effect error", e)
      }
    }
    awaitedUser()
  }, [])

  const [doc_groups, setDocGroups] = useState()

  useEffect(() => {
    if (!documents) return
    const groups = createDocGroups(documents)
    setDocGroups(groups)
  }, [documents])

  const [processedDocGroups, setProcessedDocGroups] = useState([])

  useEffect(() => {
    if (!doc_groups) return
    const processDocuments = async () => {
      const processed = await preprocessDocuments(doc_groups)
      setProcessedDocGroups(processed)
    }
    processDocuments()
  }, [doc_groups])

  useEffect(() => {
    // if (!transactions || !contacts || !user || !accounts || !documents) return
    if (!transactions || !contacts || !user || !accounts || !processedDocGroups) return
    setExecutorReport(createReport.generateExecutorDocument(transactions, contacts, user, accounts, processedDocGroups))
    // setExecutorReport(createReport.generateExecutorDocument(transactions, contacts, user, accounts, documents))
    setFinancialReport(createReport.generateFinancialDocument(transactions, contacts, user, accounts))
  }, [transactions, contacts, user, accounts, processedDocGroups])
  // }, [transactions, contacts, user, accounts, documents])

  const getUser = async () => {
    try {
      const response = await users_api.getProfile()
      setUser(response.data.profile.user)
      return response.data.profile.user
    } catch (e) {
      console.error(e)
    }
  }

  const getAccounts = () => {
    const response = outgoings_api.getAccounts()
    response
      .then((response) => {
        setAccounts(response.data.accounts)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTransactions = ({ offset, limit, transactionTypes, showHidden, userId }) => {
    const response = outgoings_api.getAllTransactions({ offset, limit, transactionTypes, showHidden, userId })
    response
      .then((response) => {
        if (offset === 0) {
          setTransactions(response.data.data)
        }
        if (offset > 0) {
          setTransactions([...transactions, ...response.data.data])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const GetContacts = () => {
    const response = contacts_api.getContacts()
    response
      .then((response) => {
        setContacts(response.data.contacts)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const GetDocuments = () => {
    const response = documents_api.getDocuments()
    response
      .then((response) => {
        setDocuments(response.data.documents)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const clickMenu = (ev) => {
    if (ev.target.id === "") return
    updateMenu(ev.target.textContent)
  }

  const updateMenu = (selection) => {
    if (!reportsMenu) return
    const updateMenu = [...reportsMenu]
    const idx = updateMenu.map((item) => item.name).indexOf(selection)
    for (let i = 0; i < updateMenu.length; i++) {
      if (i !== idx) {
        updateMenu[i].activeClass = "link report-menu-item"
      } else {
        updateMenu[i].activeClass = "active link report-menu-item"
        setShowSection(i)
      }
    }
    setReportsMenu(updateMenu)
  }

  useEffect(() => {
    isPlus()
  }, [])

  const isPlus = () => {
    const response = users_api.usersSubscription()
    response
      .then((response) => {
        const subscription = response.data.subscription
        if (process.env.NODE_ENV === "development") return
        if (!subscription) window.location.assign("/dashboard?reason=membership")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="page-container">
      <SideNav />
      <div className="reports-subpage-container">
        <div className="reports-subpage-navigation-container">
          <div className="reports-subpage-navigation-title">Your Reports</div>
          <div className="reports-menu">
            {executorReport && financialReport ? (
              <>
                {reportsMenu &&
                  reportsMenu.map((menu, idx) => {
                    return (
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                      <div key={idx} className={menu.activeClass} id={menu.name} onClick={clickMenu}>
                        {menu.name}
                      </div>
                    )
                  })}
              </>
            ) : transactions || contacts || user || accounts || documents ? (
              "Loading..."
            ) : (
              "You currently have no reports"
            )}
          </div>
        </div>
        <ExecutorReport report={executorReport} show={showSection === 0} />
        <FinancialReport report={financialReport} show={showSection === 1} />
        {/*<div className="reports-subpage-preview-container">*/}
        {/*    {executorReport && !executorReport ? null : <PDFViewer className="report-viewer">{executorReport && executorReport}</PDFViewer> }*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Reports
