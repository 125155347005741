import { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"

import outgoings_api from "../../api/outgoings"
import bud_api from "../../api/bud"
import users_api from "../../api/users"
import auth from "../../api/auth"

import SideNav from "../../components/SideNav"
import TransactionsTable from "../../components/TransactionsTable"
import AccountDetailsDialogue from "../../components/AccountDetailsDialogue"

import bud_banner from "../../assets/budBanners/budbanner.webp"

import plus from "../../assets/plus.webp"
import refresh_image from "../../assets/refresh.webp"
import bank_card_chip from "../../assets/bank-card-chip.webp"

import "swiper/css"
import "swiper/css/navigation"

import { useSearchParams } from "react-router-dom"

import BANK_LOGOS from "../../models/BankLogos"

const Banking = () => {
  const [_isAuthenticated, setIsAuthenticated] = useState()
  const [isBudAuthenticated, setIsBudAuthenticated] = useState(false)

  const [transactions, setTransactions] = useState()
  const [allTransactions, setAllTransactions] = useState()

  const [accounts, setAccounts] = useState()

  const [detailsAccount, setDetailsAccount] = useState()
  const [displayAccountDetails, setDisplayAccountDetails] = useState(false)

  const [searchParams, _setSearchParams] = useSearchParams()

  const [slidesPerView, setSlidesPerView] = useState(3)
  const [disableRefresh, setDisableRefresh] = useState(false)
  const [user, setUser] = useState()

  useEffect(() => {
    const authenticated = auth.isAuthenticated()
    if (!authenticated) window.location.assign("/login")
    else setIsAuthenticated(authenticated)

    const budAuthenticated = auth.isBudAuthenticated()
    setIsBudAuthenticated(budAuthenticated)

    const fetchData = async () => {
      try {
        const user = await getUser()
        const userId = user.id
        getAccounts()
        getTransactions({ offset: 0, limit: 10000, transactionTypes: ["loan", "subscription"], userId })
        getAllTransactions({ offset: 0, limit: 25, userId })
      } catch (e) {
        console.error("failed to fetch data", e)
      }
    }

    const handleResize = () => {
      const width = Math.min(window.innerWidth - 550, 1200)
      const num = Math.floor(width / 220)
      setSlidesPerView(num)
    }
    fetchData()
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (searchParams.get("status") === "success") {
      refreshFinancialsData()
      checkBudAuthentication()
    }
  }, [searchParams.get("status")])

  const handleLoadMore = () => {
    const offset = allTransactions.length
    if (!user) {
      console.error("no user available")
    }
    getAllTransactions({ offset, limit: 25, userId: user.id })
  }

  const checkBudAuthentication = () => {
    auth.CheckBud()
    const budAuthenticated = auth.isBudAuthenticated()
    setIsBudAuthenticated(budAuthenticated)
  }

  const getUser = async () => {
    try {
      const response = await users_api.getProfile()
      setUser(response.data.profile.user)
      return response.data.profile.user
    } catch (e) {
      console.error(e)
    }
  }

  const getAccounts = () => {
    const response = outgoings_api.getAccounts()
    response
      .then((response) => {
        setAccounts(response.data.accounts)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTransactions = (payload = {}) => {
    const { offset = 0, limit, transactionTypes, showHidden, userId = undefined } = payload
    const id = userId ?? user?.id
    if (!id) throw new Error("user id is required")
    const response = outgoings_api.getAllTransactions({ offset, limit, transactionTypes, showHidden, userId: id })
    response
      .then((response) => {
        if (offset === 0) {
          setTransactions(response.data.data)
        }
        if (offset > 0) {
          setTransactions([...transactions, ...response.data.data])
        }
      })
      .catch((err) => {
        console.log("gt error")
        console.error(err)
      })
  }

  const getAllTransactions = ({ offset = 0, limit = 25, userId }) => {
    const id = userId ?? user?.id
    if (!id) throw new Error("user id is required")
    const response = outgoings_api.getAllTransactions({ offset, limit, userId: id })
    response
      .then((response) => {
        if (offset === 0) {
          setAllTransactions(response.data.data)
        }
        if (offset > 0) {
          setAllTransactions([...allTransactions, ...response.data.data])
        }
      })
      .catch((err) => {
        console.log("gat errro")
        console.error(err)
      })
  }

  const refreshFinancialsData = () => {
    if (!user) {
      console.error("user is unavailable during refresh")
    }
    setDisableRefresh(true)
    const response = bud_api.budRefresh()
    response
      .then((_response) => {
        getAccounts()
        getTransactions({ offset: 0, limit: 10000, transactionTypes: ["loan", "subscription"], userId: user.id })
        getAllTransactions({ offset: 0, limit: 25, userId: user.id })
        setDisableRefresh(false)
      })
      .catch((err) => {
        setDisableRefresh(false)
        console.error("refresh financials", err)
      })
  }

  const thisBudAuthenticate = (ev) => {
    if (ev.target.id !== "bud") return
    const response = bud_api.budAuthenticate()
    response
      .then((response) => {
        window.location.assign(response.data.url)
      })
      .catch((err) => {
        console.error("this is bud auth", err)
      })
  }

  const openDetailsDialog = (ev) => {
    if (!ev.target.id && ev.target.id !== 0) return
    setDetailsAccount(accounts[ev.target.id])
    toggleDetailsDialogue()
  }

  const toggleDetailsDialogue = () => {
    setDisplayAccountDetails(!displayAccountDetails)
  }

  const handleRefreshFinancialsData = (ev) => {
    if (ev.target.id !== "refresh") return
    refreshFinancialsData()
  }

  useEffect(() => {
    isPlus()
  }, [])

  const isPlus = () => {
    const response = users_api.usersSubscription()
    response
      .then((response) => {
        const subscription = response.data.subscription
        if (!subscription) window.location.assign("/dashboard?reason=membership")
      })
      .catch((err) => {
        console.error("is plus", err)
      })
  }

  return (
    <div className="page-container" id="scroll-container">
      <SideNav />
      <div className="outgoings-subpage-container">
        {!isBudAuthenticated ? ( //|| accounts && transactions && (accounts.length === 0 && transactions.length === 0) || !accounts
          <div className="bud-banner-container link" onClick={thisBudAuthenticate} id="bud">
            <img alt="bud banner" src={bud_banner} id="bud" />
          </div>
        ) : (
          <>
            <div className="bank-accounts-container">
              <div className="bank-accounts-top-container">
                <h3 className="bank-accounts-title">Bank accounts</h3>
                <button
                  type="button"
                  className="bank-accounts-refresh-button"
                  id="refresh"
                  onClick={handleRefreshFinancialsData}
                  disabled={disableRefresh}
                >
                  <img
                    src={refresh_image}
                    className={disableRefresh ? "spin" : null}
                    alt="refresh accounts"
                    id="refresh"
                  />
                  <div className="bank-accounts-refresh-button-text" id="refresh">
                    Refresh accounts
                  </div>
                </button>
                <button type="button" className="bank-accounts-add-button" id="bud" onClick={thisBudAuthenticate}>
                  <img src={plus} alt="add account icon" id="bud" />
                  <div className="bank-accounts-add-button-text" id="bud">
                    Add account
                  </div>
                </button>
              </div>
              <div className="bank-accounts-bottom-container">
                <div className="bank-accounts-cards-container">
                  <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={20}
                    className="bank-accounts"
                    navigation={true}
                    modules={[Navigation]}
                  >
                    {accounts &&
                      accounts.map((account, idx) => {
                        return (
                          <SwiperSlide key={Math.random()}>
                            <div
                              className={`bank-account-card ${account.provider} link`}
                              id={idx}
                              onClick={openDetailsDialog}
                            >
                              <div className="bank-account-card-top-container" id={idx}>
                                <div className="bank-account-chip" id={idx}>
                                  <img alt="bank card chip" src={bank_card_chip} id={idx} />
                                </div>
                                <div className="bank-account-logo" id={idx}>
                                  <img
                                    alt="bank account logo"
                                    id={idx}
                                    src={account.logo !== "nologo" ? account.logo : BANK_LOGOS[account.provider]}
                                  />
                                </div>
                              </div>
                              <div className="bank-account-provider-card" id={idx}>
                                {" "}
                                {account.provider}
                              </div>
                              <div className="bank-account-id-card" id={idx}>
                                {`****${account.account_number[5] ?? ""}${account.account_number[6] ?? ""}${account.account_number[7] ?? ""}`}
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </div>
                <div className="mobile-accounts-cards-container">
                  {accounts &&
                    accounts.map((account, idx) => {
                      return (
                        <>
                          <div
                            key={Math.random()}
                            className="mobile-account-row link"
                            onClick={openDetailsDialog}
                            id="mobile-row"
                          >
                            <div className="mobile-account-logo col">
                              <img
                                alt="provider back logo"
                                id={idx}
                                src={account.logo !== "nologo" ? account.logo : BANK_LOGOS[account.provider]}
                              />
                            </div>
                            <div className="mobile-account-provider col" id={idx}>
                              {account.provider}
                            </div>
                            <div className="mobile-account-number col" id={idx}>
                              {`****${account.account_number[5] ?? ""}${account.account_number[6] ?? ""}${account.account_number[7] ?? ""}`}
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
              <AccountDetailsDialogue
                account={detailsAccount}
                display={displayAccountDetails}
                toggleDialogue={toggleDetailsDialogue.bind(this)}
                refreshData={refreshFinancialsData.bind(this)}
              />
            </div>
            <div className="tables-column flex-column">
              <TransactionsTable
                tableKey="regular_transactions"
                tableName="Regular outgoings reported"
                className="financials-table"
                transactions={transactions ?? []}
                accounts={accounts && accounts}
                refreshTransactions={getTransactions.bind(this)}
              />
            </div>
            <div className="tables-column flex-column pt-3">
              <TransactionsTable
                tableKey="all_transactions"
                tableName="All Transactions"
                className="financials-table"
                transactions={allTransactions ?? []}
                accounts={accounts ?? []}
                refreshTransactions={getAllTransactions.bind(this)}
                loadMore={handleLoadMore}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Banking
