import { useState } from "react"

import users_api from "../api/users"

import close from "../assets/close.webp"

const FeedbackPopup = (props) => {
  const [inputtedText, setInputtedText] = useState("")
  const [subpage, setSubpage] = useState(1)

  const [error, setError] = useState("")

  const toggleMenu = props.toggle

  const sendFeedback = () => {
    const data = {
      text: inputtedText,
    }
    const response = users_api.sendFeedback(data)
    response
      .then((response) => {
        if (response.data.error) {
          setError(response.data.error)
        } else setSubpage(2)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="page-mask" />
      <div className="side-nav-pop-up-container">
        <div className="pop-up-close-container">
          <img src={close} alt={"Close"} onClick={toggleMenu} className="link" />
        </div>
        <div className="pop-up-title-container">
          <div className="popup-title">Feedback</div>
        </div>
        {subpage && subpage === 1 ? (
          <>
            <div className="pop-up-description-container">
              <div className="popup-description">
                Tell us what you think! Your feedback will help us create a better platform for you.
              </div>
            </div>
            <div className="feedback-input-container">
              <textarea
                value={inputtedText}
                className="feedback-input"
                onChange={(ev) => setInputtedText(ev.target.value)}
              />
            </div>
            <div className="feedback-error">{error && error}</div>
            <div className="feedback-buttons-container">
              <div className="feedback-cancel-button link" onClick={toggleMenu}>
                {" "}
                Cancel{" "}
              </div>
              <input
                type="button"
                className="feedback-submit-button"
                onClick={(_ev) => sendFeedback()}
                value="Submit"
              />
            </div>
          </>
        ) : (
          <>
            <div className="feedback-complete-container">
              <div className="feedback-complete">Thank you for your feedback!</div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default FeedbackPopup
